/* Analytics.css */

.analytics-page {
    padding: 2rem;
    background: #f7f9fc;
    min-height: 100vh;
    font-family: 'Segoe UI', sans-serif;
  }
  
  .analytics-page h1 {
    font-size: 2rem;
    color: #1e1e1e;
    margin-bottom: 0.5rem;
  }
  
  .analytics-page p {
    color: #666;
    margin-bottom: 2rem;
  }
  
  .analytics-filter {
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .analytics-filter label {
    font-weight: 500;
    color: #333;
    margin-right: 0.5rem;
  }
  
  .analytics-filter select {
    padding: 0.4rem 0.7rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    background: white;
    font-size: 0.95rem;
    cursor: pointer;
  }
  
  .analytics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;
  }
  
  .analytics-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-align: center;
  }
  
  .analytics-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }
  
  .analytics-card h2 {
    margin: 0 0 0.5rem;
    font-size: 1.3rem;
    color: #ff6f3c;
  }
  
  .analytics-card p {
    font-size: 0.95rem;
    color: #333;
    margin: 0;
  }
  
  .analytics-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 2rem;
  }
  
  .chart-block {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  }
  
  .chart-block h3 {
    margin-bottom: 1rem;
    color: #222;
    font-size: 1.1rem;
  }
  
  