/* BuyerLeads.css – Fancy, Modern Style mit extra Highlights */

.buyer-leads {
    padding: 2rem;
    background: linear-gradient(to bottom right, #f5f7fa, #e4ecf7);
    min-height: 100vh;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .buyer-leads h1 {
    font-size: 2.4rem;
    color: #1f1f1f;
    margin-bottom: 0.25rem;
    font-weight: 700;
  }
  
  .buyer-leads p {
    color: #555;
    margin-bottom: 2.2rem;
    font-size: 1.05rem;
  }
  
  .lead-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .lead-card {
    background: white;
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.07);
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    position: relative;
  }
  
  .lead-card::before {
    content: '\1F50D'; /* 🔍 Suchsymbol */
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 2rem;
    opacity: 0.25;
  }

  .lead-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.75rem;
    box-shadow: 0 0 0 2px #ff6f3c33;
  }
  
  .lead-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
    border-color: #ff6f3c33;
  }
  
  .lead-card h3 {
    margin: 0;
    font-size: 1.4rem;
    color: #ff6f3c;
  }
  
  .lead-card p {
    margin: 0.35rem 0;
    font-size: 0.96rem;
    color: #333;
  }
  
  .lead-card strong {
    color: #222;
    font-weight: 600;
  }
  
  .btn-chat {
    margin-top: auto;
    padding: 0.65rem 1.3rem;
    background: linear-gradient(90deg, #ff6f3c, #ffa74f);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.2s ease;
    box-shadow: 0 2px 6px rgba(255, 111, 60, 0.3);
  }
  
  .btn-chat:hover {
    background: linear-gradient(90deg, #e65923, #ff884d);
  }

  .lead-avatar-wrapper {
    position: relative;
    width: 56px;
    height: 56px;
    margin-bottom: 0.75rem;
  }
  
  /* .lead-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 0 0 2px #ff6f3c33;
    display: block;
  } */
  
  .lead-avatar-fallback {
    width: 100%;
    height: 100%;
    background-color: #ff6f3c;
    color: white;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 0 0 2px #ff6f3c33;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  
  