.filters-page {
    padding: 2rem;
  }
  
  .filters-page h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .filters-page p {
    margin-bottom: 1.5rem;
    color: #555;
  }
  
  .filter-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .filter-card {
    flex: 1 1 300px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .filter-card h3 {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
  }
  
  .filter-card p {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 0.75rem;
  }
  
  .match-count {
    font-weight: bold;
    color: #444;
    margin-bottom: 1rem;
  }
  
  .filter-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .btn-apply,
  .btn-delete {
    flex: 1;
    padding: 0.6rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .btn-apply {
    background-color: #4caf50;
    color: white;
  }
  
  .btn-delete {
    background-color: #f44336;
    color: white;
  }
  

  .filter-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 2rem;
    max-width: 600px;
  }
  
  .filter-form input,
  .filter-form textarea {
    padding: 0.7rem 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 0.95rem;
    background-color: #f9f9f9;
    resize: vertical;
  }
  
  .filter-form textarea {
    min-height: 80px;
  }
  
  .btn-add {
    align-self: flex-start;
    background-color: #007bff;
    color: white;
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-add:hover {
    background-color: #0056b3;
  }

  /* Formularstruktur */
.filter-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    max-width: 600px;
  }
  
  .filter-form input,
  .filter-form select,
  .filter-form textarea {
    padding: 0.7rem 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 0.95rem;
    background-color: #f9f9f9;
  }
  
  .filter-form .price-range {
    display: flex;
    gap: 1rem;
  }
  
  .filter-form .price-range input {
    flex: 1;
  }
  
  /* Button */
  .btn-add {
    align-self: flex-start;
    background-color: #007bff;
    color: white;
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-add:hover {
    background-color: #0056b3;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0.5rem 0 1rem;
  }
  
  .tag {
    background-color: #eee;
    color: #333;
    padding: 0.3rem 0.7rem;
    font-size: 0.85rem;
    border-radius: 999px;
    font-weight: 500;
  }

  .filter-card.active {
    border: 2px solid #007bff;
    background-color: #f0f8ff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.15);
  }
  
  .filter-card.active h3 {
    color: #007bff;
  }
  
  
  
  