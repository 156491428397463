/* Messages.css */

.messages-page {
    padding: 2rem;
    background: #f7f9fc;
    min-height: 100vh;
    font-family: 'Segoe UI', sans-serif;
  }
  
  .messages-page h1 {
    font-size: 2rem;
    color: #1e1e1e;
    margin-bottom: 0.5rem;
  }
  
  .messages-page p {
    color: #666;
    margin-bottom: 2rem;
  }
  
  .chat-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  .chat-preview {
    background: white;
    border-radius: 12px;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.15s ease;
  }
  
  .chat-preview:hover {
    transform: translateY(-2px);
  }
  
  .chat-initials {
    width: 48px;
    height: 48px;
    background-color: #ff6f3c;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.1rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .chat-info {
    flex-grow: 1;
  }
  
  .chat-info h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #222;
  }
  
  .chat-info p {
    margin: 0.2rem 0 0;
    color: #555;
    font-size: 0.95rem;
  }
  
  .chat-time {
    font-size: 0.85rem;
    color: #999;
    margin-left: 1rem;
    white-space: nowrap;
  }
  