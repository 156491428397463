/* Dashboard.css – stylisch, klar, responsive */

.dashboard-page {
    padding: 2rem;
    background: linear-gradient(to bottom right, #f3f7fb, #e6eef7);
    min-height: 100vh;
    font-family: 'Segoe UI', sans-serif;
    box-sizing: border-box;
  }
  
  .dashboard-page h1 {
    font-size: 2rem;
    margin-bottom: 0.25rem;
    color: #1f1f1f;
    font-weight: 700;
  }
  
  .dashboard-page p {
    margin-bottom: 2rem;
    font-size: 1rem;
    color: #555;
  }
  
  .kpi-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;
  }
  
  .kpi-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease;
  }
  
  .kpi-card:hover {
    transform: translateY(-4px);
  }
  
  .kpi-card h2 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: #ff6f3c;
  }
  
  .kpi-card p {
    font-size: 0.95rem;
    color: #333;
  }
  
  .insight-section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1f1f1f;
  }
  
  .insight-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
  }
  
  .insight-card {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    border-left: 5px solid #ff6f3c;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: background 0.2s ease;
  }
  
  .insight-card h3 {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
    color: #222;
  }
  
  .insight-card p {
    font-size: 0.95rem;
    color: #555;
    margin: 0;
  }