/* Boost.css – Fancy Boost Styling */

.boost-page {
    padding: 2rem;
    background: linear-gradient(to bottom right, #f0f4f9, #eaf0fc);
    min-height: 100vh;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
  }
  
  .boost-page h1 {
    font-size: 2.3rem;
    color: #1e1e1e;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .boost-page p {
    color: #555;
    font-size: 1.05rem;
    margin-bottom: 1.5rem;
  }
  
  .boost-suggestion-box {
    background: #fff4eb;
    border-left: 5px solid #ff6f3c;
    padding: 1.5rem 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 3px 10px rgba(0,0,0,0.05);
  }
  
  .boost-suggestion-box h2 {
    margin: 0 0 0.5rem;
    font-size: 1.4rem;
    color: #ff6f3c;
  }
  
  .boost-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
  }
  
  .boost-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 8px 20px rgba(0,0,0,0.08);
    text-align: center;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .boost-card:hover {
    transform: translateY(-5px);
  }
  
  .boost-icon {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  
  .boost-card h3 {
    font-size: 1.25rem;
    color: #1f1f1f;
    margin-bottom: 0.5rem;
  }
  
  .boost-card p {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 1.2rem;
  }
  
  .boost-price {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ff6f3c;
    margin-bottom: 1rem;
  }
  
  .boost-btn {
    background: linear-gradient(90deg, #ff6f3c, #ffa84c);
    color: white;
    border: none;
    padding: 0.7rem 1.4rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .boost-btn:hover {
    background: linear-gradient(90deg, #e65923, #ff944d);
  }

  .boost-suggestion-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  
  