.sidebar {
    width: 220px;
    background-color: #1f1f1f;
    color: white;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #FFA74F;
    padding-left: 10px;
  }
  
  .sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .sidebar-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }
  
  .sidebar-link:hover {
    background-color: #333;
  }
  
  .sidebar-link.active {
    background-color: #FF6F3C;
    color: white;
  }